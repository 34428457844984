<template>
  <v-row class="text-body-2">
    <v-col cols="6">
      <img
        :src="image.image_url"
        style="width: 100%"
      >

      <canvas
        ref="canvas"
        :height="height"
        :width="width"
        style="zoom: 0.7"
      />

      <proctoring2face-orientation
        :roll="Number(image.face_results[0]?.pose[0])"
        :yaw="Number(image.face_results[0]?.pose[1])"
        :pitch="Number(image.face_results[0]?.pose[2])"
      />
    </v-col>
    <v-col cols="6">
      {{ image.id }}
      <br>
      <span class="text-h6">
        Objects detected
      </span>
      <div
        v-for="(object, index) in image.detection_results"
        :key="index"
      >
        <b>{{ object.label }}</b> {{ object.score }}
        <br>
        <v-icon
          v-html="'mdi-crosshairs-gps'"
          small
        />
        {{ object.box.map(x => Math.round(x)).join(', ') }}
        <v-divider />
      </div>


      <br>
      <span class="text-h6">
        Faces
      </span>

      <div
        v-for="(face, index) in image.face_results"
        :key="index"
      >
        <v-icon
          small
          v-html="'mdi-face-recognition'"
        />
        <b>Face {{ index }}</b>
        <v-btn
          x-small
          depressed
          @click="setEmbedding(face)"
        >
          Set as reference
        </v-btn>
        <br>
        <b>Gaze</b>
        <br>
        Gaze - pitch: {{ face.gaze[0] }}
        <br>
        Gaze - yaw: {{ face.gaze[1] }}
        <br>
        <b>Pose</b>
        <v-icon
          x-small
          v-if="index === 0"
        >
          mdi-cube-outline
        </v-icon>
        <br>
        Pose - Pitch - Rx: {{ face.pose[2] }}
        <br>
        Pose - Yaw - Ry: {{ face.pose[1] }}
        <br>
        Pose - Roll - Rz: {{ face.pose[0] }}
        <br>
        <br>
        Person position {{ face.person_position.map(x => Math.round(x)).join(', ') }}
        <br>
        Face position {{ face.face_position.map(x => Math.round(x)).join(', ') }}
        <br>
        <template v-if="embeddingReferences.length > 0 ">
          Embedding distance
          <span
            :class="
              Math.min(...embeddingDistance(face.embedding)) < Number(distanceThresholds.euclidean.max) ? 'green--text': 'red--text'
            "
          >
            - Min : {{ Math.min(...embeddingDistance(face.embedding)) }}
          </span>
          - Max : {{ Math.max(...embeddingDistance(face.embedding)) }}
          {{ embeddingDistance(face.embedding) }}
          <br>
          Cosine similarity
          - Min : {{ Math.min(...embeddingDistanceCosine(face.embedding)) }}
          <span
            :class="
              Math.max(...embeddingDistanceCosine(face.embedding)) > Number(distanceThresholds.cosine.min) ? 'green--text': 'red--text'
            "
          >
            - Max : {{ Math.max(...embeddingDistanceCosine(face.embedding)) }}
          </span>
          {{ embeddingDistanceCosine(face.embedding) }}
        </template>
        <v-divider />
      </div>
      <!--      <v-text-field-->
      <!--        v-model="xD"-->
      <!--        @change="draw"-->
      <!--        @input="draw"-->
      <!--        type="number"-->
      <!--      />-->
      <!--      <v-text-field-->
      <!--        v-model="yD"-->
      <!--        @change="draw"-->
      <!--        @input="draw"-->
      <!--        type="number"-->
      <!--      />-->
      <!--      <v-text-field-->
      <!--        v-model="zD"-->
      <!--        @change="draw"-->
      <!--        @input="draw"-->
      <!--        type="number"-->
      <!--      />-->

      <!--      <v-btn @click="draw()">-->
      <!--        draw-->
      <!--      </v-btn>-->
    </v-col>
  </v-row>
</template>
<script>
import mathsHelpers from "../../helpers/maths_helpers"
import Proctoring2faceOrientation from "./Proctoring2faceOrientation.vue"

export default {
  name: "Proctoringv2DebugImage",
  components: { Proctoring2faceOrientation },
  props: {
    image: {
      type: Array,
      default: () => [],
    },
    embeddingReferences: {
      type: Array,
      default: () => [],
    },
    distanceThresholds: {
      type: Object,
      default: () => ({
        euclidean: {
          max: 0.8,
        },
        cosine: {
          min: 0.8,
        },
      }),
    },
  },
  data: () => ({
    vueCanvas: null,
    width: 640,
    height: 480,
    yoloHeight: 640,
    xD: 0,
    yD: 0,
    zD: 0,

  }),

  methods: {
    draw() {
      // clear canvas
      this.vueCanvas.clearRect(0, 0, this.width, this.height)
      this.addBackground()
      this.addImage(this.image.image_url)

      // this.addRectangle(0, 100, 0, 10, "x", "green" )
      // this.addRectangle(0, 10, 0, 100, "y", "orange" )

      this.image.detection_results.forEach( object => {
        this.addRectangle(
          object.box[0],
          object.box[1] * this.height / this.yoloHeight,
          object.box[2],
          object.box[3] * this.height / this.yoloHeight,
          `${object.label} ${Math.round(object.score * 100) / 100}` ,
        )
      })

      this.image.face_results.forEach( (face,index) => {

        this.addRectangle(
          face.person_position[0],
          face.person_position[1],
          face.person_position[2],
          face.person_position[3],
          `Person-${index}` ,
          "rgba(0,255, 0, 1)",
          false
        )


        this.addRectangle(
          Number(face.face_position[0]) + Number(face.person_position[0]),
          Number(face.face_position[1]) + Number(face.person_position[1]),
          Number(face.face_position[2]) + Number(face.person_position[0]),
          Number(face.face_position[3]) + Number(face.person_position[1]),
          `Face-${index}` ,
          "rgba(0,0, 255, 1)",
          false
        )



        this.drawPoseAxis(
          Number(face?.pose[0]) + Number(this.xD),
          Number(face?.pose[1]) + Number(this.yD),
          Number(face?.pose[2]) + Number(this.zD),
          100,
          {
            x_start: Number(face.person_position[0]),
            y_start: Number(face.person_position[1]),
            x_end: Number(face.person_position[2]),
            y_end: Number(face.person_position[3]),
          },
        )

        this.drawGazeAxis(
          Number(face.gaze[0]),
          Number(face.gaze[1]),
          100,
          {
            x_start: Number(face.person_position[0]),
            y_start: Number(face.person_position[1]),
            x_end: Number(face.person_position[2]),
            y_end: Number(face.person_position[3]),
          },
        )
      })
    },
    addBackground() {
      this.vueCanvas.fillStyle = "#dddddd"
      this.vueCanvas.fillRect(0, 0, this.width, this.height)
    },
    addRectangle(x_start, y_start, x_end, y_end, label, color = "rgba(255, 0, 0, 0.1)", fill = true ) {
      const width = x_end - x_start
      const height = y_end - y_start
      this.vueCanvas.fillStyle = color
      this.vueCanvas.strokeStyle = color
      this.vueCanvas.lineWidth = 3
      if(fill) {
        this.vueCanvas.fillRect(x_start, y_start, width, height)
      } else {
        this.vueCanvas.strokeRect(x_start, y_start, width, height)
      }
      this.vueCanvas.rect(
        x_start,
        y_start,
        width,
        height
      )
      //add a text in the top left corner
      this.vueCanvas.font = "12px Arial"
      this.vueCanvas.fillStyle = "#000000"
      this.vueCanvas.fillText(label, x_start, y_start + 12)
    },

    //draw a line representing a 3d direction from a 2d point
    drawDirectionLine(roll, yaw, pitch) {

      var x = this.convertToX(roll, yaw, pitch)
      var y = this.convertToY(roll, yaw, pitch)

      this.vueCanvas.beginPath()
      this.vueCanvas.moveTo(this.width / 2, this.height / 2)
      this.vueCanvas.lineTo(x, y)
      this.vueCanvas.stroke()
    },

    EulerToMatrix(roll, yaw, pitch) {
      // roll - z axis
      // yaw  - y axis
      // pitch - x axis
      roll = roll / 180 * Math.PI
      yaw = yaw / 180 * Math.PI
      pitch = pitch / 180 * Math.PI

      const rz = [
        [Math.cos(roll), -Math.sin(roll), 0],
        [Math.sin(roll), Math.cos(roll), 0],
        [0, 0, 1],
      ]

      const ry = [
        [Math.cos(yaw), 0, Math.sin(yaw)],
        [0, 1, 0],
        [-Math.sin(yaw), 0, Math.cos(yaw)],
      ]

      const rx = [
        [1, 0, 0],
        [0, Math.cos(pitch), -Math.sin(pitch)],
        [0, Math.sin(pitch), Math.cos(pitch)],
      ]

      let matrix = mathsHelpers.mmultiply(rx, ry)
      matrix = mathsHelpers.mmultiply(matrix, rz)

      return matrix
    },

    defaultBox() {
      return {
        x_start: 0,
        y_start: 0,
        x_end: this.width,
        y_end: this.height,
      }
    },

    drawPoseAxis(roll, yaw, pitch, size = 100, position = this.defaultBox()) {
      const tdx =  (position.x_end - position.x_start) / 2 + position.x_start
      const tdy = (position.y_end - position.y_start) / 2 + position.y_start


      var matrix = this.EulerToMatrix(-roll, -yaw, -pitch)

      let xAxis = [matrix[0][0], matrix[1][0], matrix[2][0]].map(x => x * size)
      let yAxis = [matrix[0][1], matrix[1][1], matrix[2][1]].map(x => x * size)
      let zAxis = [matrix[0][2], matrix[1][2], matrix[2][2]].map(x => x * size)

      // console.log({ tdx, tdy, Xaxis: xAxis, Yaxis: yAxis, Zaxis: zAxis })

      this.drawArrow(tdx, tdy, xAxis[0] + tdx, -xAxis[1] + tdy, 3, "red")
      this.drawArrow(tdx, tdy, -yAxis[0] + tdx, yAxis[1] + tdy, 3 ,"green")
      this.drawArrow(tdx, tdy, zAxis[0] + tdx, -zAxis[1] + tdy, 3 ,"blue")

    },

    drawGazeAxis(pitch, yaw, size = 100, position = this.defaultBox()) {
      const tdx =  (position.x_end - position.x_start) / 2 + position.x_start
      const tdy = (position.y_end - position.y_start) / 2 + position.y_start

      const dx = - size * Math.sin(pitch) * Math.cos(yaw)
      const dy = - size * Math.sin(yaw)

      this.drawArrow(tdx, tdy, dx + tdx, dy + tdy, 3, "rgba(238,0,255,0.7)")
    },


    drawArrow(fromx, fromy, tox, toy, arrowWidth, color){
      const ctx = this.vueCanvas
      //variables to be used when creating the arrow
      // var headlen = 10
      // var angle = Math.atan2(toy - fromy,tox - fromx)

      ctx.save()
      ctx.strokeStyle = color

      //starting path of the arrow from the start square to the end square
      //and drawing the stroke
      ctx.beginPath()
      ctx.moveTo(fromx, fromy)
      ctx.lineTo(tox, toy)
      ctx.lineWidth = arrowWidth
      ctx.stroke()

      //starting a new path from the head of the arrow to one of the sides of
      //the point
      // ctx.beginPath()
      // ctx.moveTo(tox, toy)
      // ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7),
      //            toy - headlen * Math.sin(angle - Math.PI / 7))

      // //path from the side point of the arrow, to the other side point
      // ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 7),
      //            toy - headlen * Math.sin(angle + Math.PI / 7))
      //
      // //path from the side point back to the tip of the arrow, and then
      // //again to the opposite side point
      // ctx.lineTo(tox, toy)
      // ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7),
      //            toy - headlen * Math.sin(angle - Math.PI / 7))

      //draws the paths created above
      ctx.stroke()
      ctx.restore()
    },

    addImage(url) {
      const image = new Image()
      image.src = url
      image.onload = () => {
        this.vueCanvas.save()
        this.vueCanvas.globalAlpha = 0.2
        this.vueCanvas.drawImage(image, 0, 0, this.width, this.height)
        this.vueCanvas.restore()
      }
    },

    setEmbedding(face) {
      // console.log(face.embedding)
      this.$emit("set-embedding", face.embedding)
    },
    initCanvas() {
      const canvas = this.$refs.canvas
      this.vueCanvas =  canvas.getContext("2d")
    },
  },

  computed: {
    embeddingDistance() {
      return (embedding) => {
        return this
          .embeddingReferences
          .map(ref => Math.round(mathsHelpers.euclideanDistance(embedding.map(x=> Number(x)), ref.map(x=> Number(x))) * 100) / 100)
      }
    },
    embeddingDistanceCosine() {
      return (embedding) => {
        return this
          .embeddingReferences
          .map(ref => Math.round(mathsHelpers.cosineSimilarity(embedding.map(x=> Number(x)), ref.map(x=> Number(x))) * 100) / 100)
      }
    },
  },

  mounted() {
    this.initCanvas()
  },
  watch: {
    image: {
      handler() {
        this.draw()
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
