<template>
  <div ref="three" />
</template>

<script>
import * as THREE from "three"

export default {
  name: "Proctoring2faceOrientation",
  props: {
    roll: {
      type: Number,
      default: 0,
    },
    yaw: {
      type: Number,
      default: 0,
    },
    pitch: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      width: 640,
      height: 480,
      scene: null,
      camera: null,
      renderer: null,
      cube: null,
    }
  },
  methods: {
    defaultBox() {
      return {
        x_start: 0,
        x_end: 0,
        y_start: 0,
        y_end: 0,
      }
    },
    initScene() {
      this.scene = new THREE.Scene()
      this.camera = new THREE.PerspectiveCamera( 20, this.width / this.height, 0.1, 7000 )
      this.renderer = new THREE.WebGLRenderer()
      this.renderer.setSize( this.width / 2, this.height / 2 )
      this.$refs.three.appendChild( this.renderer.domElement )

      // Rotate the scene around the x-axis to point the y axis to the bottom
      this.scene.rotation.x = Math.PI
      const axesHelper = new THREE.AxesHelper( 200 )
      this.scene.add( axesHelper )
      this.camera.position.set(0, 0, 1000)

      // Lights
      var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.1 )
      this.scene.add( ambientLight )

      const directionalLight = new THREE.DirectionalLight( 0xffffff, 1 )
      directionalLight.position.set( 0, 0, -1000 )
      this.scene.add( directionalLight )

      this.renderer.render( this.scene, this.camera )
    },
    draw3DCube() {

      const cubeSize = 150

      const geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize)
      const materials = [
        new THREE.MeshPhongMaterial({ color: 0xffff00 }), // left cheek of the face
        new THREE.MeshPhongMaterial({ color: 0xff0000 }), // right cheek of the face
        new THREE.MeshPhongMaterial({ color: 0xff00ff }), // bellow thead
        new THREE.MeshPhongMaterial({ color: 0x00ff00 }), // hairs
        new THREE.MeshPhongMaterial({ color: 0x00ffff }), // back of the head
        new THREE.MeshPhongMaterial({ color: 0x0000ff }), // noze
      ]


      this.cube = new THREE.Mesh( geometry, materials )


      this.scene.add( this.cube )
    },

    rotateCube(roll, yaw, pitch) {
      // roll yaw pitch mapped according https://github.com/Shaw-git/Lightweight-Head-Pose-Estimation/blob/main/utils/tools.py#L5
      const rX = -Number(pitch) * Math.PI / 180
      const rY = Number(yaw) * Math.PI / 180
      const rZ = Number(roll ) * Math.PI / 180
      // cube.position.x = centerX
      // cube.position.y = centerY
      this.cube.rotation.x = rX
      this.cube.rotation.y = rY
      this.cube.rotation.z = rZ
    },
    render() {
      this.renderer.render( this.scene, this.camera )
    },
  },

  mounted() {
    this.initScene()
    this.draw3DCube()
    this.rotateCube(this.roll, this.yaw, this.pitch)
    this.render()
  },

  watch: {
    roll() {
      this.rotateCube(this.roll, this.yaw, this.pitch)
      this.render()
    },
    yaw() {
      this.rotateCube(this.roll, this.yaw, this.pitch)
      this.render()
    },
    pitch() {
      this.rotateCube(this.roll, this.yaw, this.pitch)
      this.render()
    },
  },

}
</script>

<style scoped>

</style>
